import * as React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { SiApplepodcasts, SiSpotify, SiGooglepodcasts, SiOvercast, SiYoutube, SiPatreon, SiBuymeacoffee, SiTwitter, SiFacebook, SiInstagram } from "react-icons/si";
import { IoAirplaneSharp } from "react-icons/io5";
import { Link } from "gatsby";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import maitaiVid from "../assets/maitai_2.mp4";
import maitaiStill from "../assets/maitai-still.png";
import BlogRoll from "../components/BlogRoll";










const ListenButton = ({ title }) => {
  return (
    <div>
      <p>{title}</p>
    </div>
  )
}


const IndexPage = () => {

  return (
    <div>
      <Seo title="Home" />
      <Nav />
      <main>

        {/* Maitai  section */}
        <div className="bg-black md:h-[44rem] h-[50rem] flex justify-center items-center px-4">

          <div className="flex flex-col md:flex-row md:space-x-10 space-y-10 md:space-y-0 items-center">
            {/* box left */}
            <div>

              <div className="w-[14rem] md:w-[24rem] bg-black rounded hidden 2xl:block" dangerouslySetInnerHTML={{
                __html: `
                  <video src="${maitaiVid}" muted autoplay playsinline loop webkit-playsinline />
                `
              }} />

              <div className="w-[14rem] md:w-[24rem] bg-black rounded 2xl:hidden" >
                <StaticImage src="../assets/maitai-still.png" />
              </div>

            </div>

            {/* box right */}
            <div>
              {/* title */}
              <div>
                <p className="text-2xl md:text-5xl font-bold text-rose-500 ">Mai's Maitai</p>
              </div>

              {/* recipe */}
              <ol className="list-decimal space-y-2 font-mono font-semibold md:text-xl text-md pt-6 pl-9 max-w-xs text-gray-50">
                <li>slice of lime (sqeezed) </li>
                <li>30 ml light rum</li>
                <li>15 ml Orange Curaçao or Triple sec</li>
                <li>15 ml orgeat or amaretto</li>
                <li>60 ml pineapple juice (fresh squeezed)</li>
                <li>mix mix mix</li>
                <li>top with 30 ml dark rum and a garnish of your choice</li>

              </ol>

            </div>
          </div>
        </div>

        {/* Links  section */}
        <div>
          <div className="flex flex-col md:flex-row md:space-x-20 bg-white justify-center items-center">

            <div className="flex flex-col justify-center items-center pt-10">
              <p className="font-bold text-2xl text-rose-600">support us</p>
              <div className="h-[10rem]  flex flex-row space-x-6 justify-center items-center font-thin">
                <div className="flex flex-col justify-center items-center space-y-3">
                  <div className="text-4xl"><a href="https://www.patreon.com/Maitaisatsunset" className=" hover:text-rose-600"><SiPatreon /></a></div>
                </div>
                <div className="flex flex-col justify-center items-center space-y-3">
                  <div className="text-4xl"><a href="https://www.buymeacoffee.com/MaiTaisAtSunset" className=" hover:text-rose-600"><SiBuymeacoffee /></a></div>
                </div>

              </div>
            </div>

            <div className="h-[10rem] w-[0.1rem] bg-black rounded hidden md:flex"></div>

            <div className="h-[0.1rem] w-full bg-black rounded flex md:hidden"></div>

            <div className="flex flex-col justify-center items-center pt-10">
              <p className="font-bold text-2xl text-rose-600">listen on</p>
              <div className="h-[10rem] font-thin flex flex-row justify-center items-center space-x-6">

                <div className="flex flex-col justify-center items-center space-y-3">
                  <div className="text-4xl"><a href="https://podcasts.apple.com/us/podcast/mai-tais-at-sunset/id1546679018" className=" hover:text-rose-600"><SiApplepodcasts /></a></div>
                </div>

                <div className="flex flex-col justify-center items-center space-y-3 ">
                  <div className="text-4xl"><a href="https://open.spotify.com/show/3QJiUODmEEtDu3E5JR9Fli?si=7510db630e224f29" className=" hover:text-rose-600"><SiSpotify /></a></div>
                </div>

                <div className="flex flex-col justify-center items-center space-y-3">
                  <div className="text-4xl"><a href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy80ZDU3MWRkNC9wb2RjYXN0L3Jzcw" className=" hover:text-rose-600"><SiGooglepodcasts /></a></div>
                </div>


                <div className="flex flex-col justify-center items-center space-y-3">
                  <div className="text-4xl"><a href="https://www.youtube.com/channel/UCbhUinyTt6wpT48Vw7rxxZQ/" className=" hover:text-rose-600"><SiYoutube /></a></div>
                </div>

              </div>
            </div>


          </div>
          <div className="py-2 w-full bg-rose-600"></div>
        </div>

        {/* main section */}
        <div className="py-10 bg-gray-100 flex justify-center items-center">

          <div className="flex  flex-col-reverse space-y-10 space-y-reverse lg:flex-row px-4 justify-center items-start lg:space-x-6 ">

            <BlogRoll />

            <div className="lg:max-w-sm w-full">
              <div className="space-y-6 bg-gradient-to-br from-rose-600 to-yellow-400 px-2 py-2 rounded-xl">
                <div className="py-4 px-4 bg-gray-50 rounded-xl shadow-xl ">
                  <p className="md:text-3xl text-xl font-semibold pb-6 text-center">Follow us!</p>

                  <ul className="justify-center items-center flex flex-row space-x-4">
                    <li className=" flex flex-row items-center space-x-4">
                      <a href="https://twitter.com/maitaisatsunset" className=" hover:text-rose-600"><SiTwitter className="text-3xl" /></a>

                    
                    </li>
                    <li className=" flex flex-row items-center space-x-4">
                      <a href="https://www.facebook.com/maitaisatsunset/" className=" hover:text-rose-600"><SiFacebook className="text-3xl" /></a>

                  
                    </li>
                    <li className=" flex flex-row items-center space-x-4">
                      <a href="https://www.instagram.com/maitaisatsunset/" className=" hover:text-rose-600"><SiInstagram className="text-3xl" /></a>

                     
                    </li>
                    <li className=" flex flex-row items-center space-x-4">
                      <a href="https://www.youtube.com/channel/UCbhUinyTt6wpT48Vw7rxxZQ/" className=" hover:text-rose-600"><SiYoutube className="text-3xl" /></a>

                     
                    </li>


                  </ul>
                </div>

                <div className="py-4 px-4 bg-gray-50 rounded-xl space-y-3 shadow-xl flex justify-center items-center flex-col">
                  <p className="md:text-3xl text-xl font-semibold pb-3 text-center">Ask us Anything!</p>
                  <p className="">You got questions, we got answers! Email the address below to have your questions answered live on air!</p>
                  <p className="text-center"><a href="mailto:maiandtai@maitaisatsunset.com" className=" text-rose-700 hover:text-rose-300  text-sm md:text-xl md:font-semibold">maiandtai@maitaisatsunset.com</a></p>
                </div>


              </div>
            </div>

          </div>
        </div>


      </main >
      <Footer />
    </div >
  )
}

export default IndexPage
